<template>
  <div class="container">
    <div class="content">
      <p class="title">加入会议</p>
      <!-- <p class="title">{{$t('home.joinMeeting')}}</p> -->
      <van-field
      v-if="!joinParamData || joinParamData && joinParamData.businessType != 10007"

      :disabled="lockInput"
      :placeholder="$t('login.numberTips')" class="input number" v-model="conferenceNo">
        <template #button>
          <van-icon v-if="historyNumberList.length > 0" name="arrow-down" class="number-arrow" @click="openNumberHistory" />
        </template>
      </van-field>
      <van-field :disabled="lockInput || joinParamData.businessType == 10007" :placeholder="$t('login.tipsName')" maxlength="40" class="input name" v-model="userName" />

      <!-- 业务组件 加入会议按钮--->
      <join-button
        class="join"
        :userName="userName"
        :conferenceNo="conferenceNo"
        @join-finish="joinFinishHandle"
        :active="conferenceNo && userName"
      >{{$t('home.joinMeeting')}}</join-button>

      <van-button v-if="!joinParamData || joinParamData && joinParamData.businessType != 10007"  type="primary" class="button back" @click="handleBack">{{$t('common.back')}}</van-button>

      <div class="set-item first">
        <span class="label">{{$t('login.openMicrophone')}}</span>
        <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="isEnableMicro" size="24" />
      </div>
      <!-- <div class="set-item">
        <span class="label">{{$t('login.openSpeaker')}}</span>
        <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="isEnableSpeaker" size="24" />
      </div> -->
      <div class="set-item">
        <span class="label">{{$t('login.openCamera')}}</span>
        <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="isEnableVideo" size="24" />
      </div>
      <div class="set-item" v-if="supportFace">
        <span class="label">{{$t('login.openFace')}}</span>
        <van-switch class="switch" @change="onChangeFace" active-color="#1AB370" inactive-color="#d9d9d9" v-model="isEnableFace" size="24" />
      </div>
      <div class="set-item" v-if="isEnableFace && supportFace" @click="showFaceSetting = true">
        <span class="label">{{$t('login.openSetFace')}}</span>
        <my-icon fontSize="28" class="arrow" iconName="icon-jiantou_zuo_48" />
      </div>
    </div>

    <!-- 底部历史列表弹窗 -->
    <van-popup v-model="showHistoryWin" position="bottom" class="history-win" custom-style='background: #FAFAFA;'>
      <div class="head">
        <div class="bar"></div>
        <div class="link-area">
            <span @click="onClearNumber">{{$t('login.clearLog')}}</span>
            <span @click="onConfirmNumber">{{$t('login.sure')}}</span>
        </div>
       </div>
        <van-list class="list"
            :finished="true">
          <van-cell v-for="(item, idx) in historyNumberList"
            :key="item.number"
            class="cell"
            @click="onSelectedNumber(item, idx)"
            >
             <div class="item">
                <div  :class="currentNumber === item.number ?'colr_style' :'set_name'">{{item.title}}</div>
                <div :class="currentNumber === item.number ?'colr_style' :''">{{item.number}}</div>
             </div>
          </van-cell>
        </van-list>
    </van-popup>

    <!-- 美颜设置弹窗 -->
    <face v-if="supportFace" v-model="showFaceSetting" @close="showFaceSetting = false"></face>
  </div>
</template>

<script>
import { strToBoolean } from '@/utils/str'
import { loadLocal, saveLocal, removeLocal, isArray, formatCode,getUrlDate } from '@/utils/tool'
import {
  getLocalConferenceNo,
  setLocalConferenceNo,
  getDisplayName,
  setDisplayName,
  getLocalUserInfo,
  getToken } from '@/utils/auth'

import JoinButton from '@/components/button/JoinButton'
import Face from '@/components/Face/index'

import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      isEnableMicro: false,
      isEnableSpeaker: false,
      isEnableVideo: false,
      isEnableFace: false,
      showHistoryWin: false,
      showFaceSetting: false,
      conferenceNo: '',
      userName: '',
      currentNumber: '',

      historyNumberList: [],
      joinParamData:''
    }
  },

  components: {
    JoinButton,
    Face
  },

  mounted() {
    this.init();
  },

  computed: {
    enableFace () {
      return this.$store.state.meet.enableFace
    },

    supportFace () {
      return this.$store.state.face.support
    },

    lockInput() {
      return this.$route.query.lock == 1;
    }
  },

  created() {
    let paramsData = getUrlDate(window.location.href)
    if(!paramsData || !paramsData.param) return
    const secretKey = '5szizEl7eHBryFsWub30APkG6ujhVkVYktRxq8AtQH9Scf9xD7uC5VY27sR6wgu9';
    //加密规则用AES加密算法，最后的param需要encodeURIComponent一下。secretKey:"5szizEl7eHBryFsWub30APkG6ujhVkVYktRxq8AtQH9Scf9xD7uC5VY27sR6wgu9"，需要传过来的参数 businessType、accessToken、name、hospital、conferenceNo，下面是示例
    // const data = JSON.stringify({
    //   businessType:10007,//业务类型 写死
    //   accessToken:'xxxxx',
    //   name:'小虎', //参会人员名称
    //   hospital:'朝阳医院',//医院名称
    //   conferenceNo:'426398732'//会议号
    // });

    // let param = encodeURIComponent(CryptoJS.AES.encrypt(data, secretKey).toString())
    let paramData =  CryptoJS.AES.decrypt(decodeURIComponent(paramsData.param), secretKey).toString(CryptoJS.enc.Utf8);
    paramData = JSON.parse(paramData)
    if(paramData && paramData.businessType){
      this.joinParamData = paramData
    }
    // console.error(111111133,paramData)
  },
  watch: {
    enableFace(value) {
      //同步设置里面美颜开关切换
      this.isEnableFace = value
    }
  },


  methods: {
    init() {
      let name = this.joinParamData && this.joinParamData.businessType == 10007 ? this.joinParamData.name : this.$route.query.name;
      let conferenceNo = this.joinParamData && this.joinParamData.businessType == 10007 ? this.joinParamData.conferenceNo : this.$route.query.conferenceNo;
;
      if (conferenceNo) { // 邀请函，url上附加conferenceNo
        this.conferenceNo = conferenceNo;
      } else { // 查看本地存储
        this.conferenceNo = getLocalConferenceNo() || ''
      }
      
      //异常退会 导致的重新入会 重置会议号信息
      const exceptionExitMsgObj = JSON.parse(localStorage.getItem('exceptionExitMsg') || '{}')
      const exceptionConferenceNo = this.$meetingStorage.getItem('appHide') || exceptionExitMsgObj.conferenceNo
      if (exceptionConferenceNo) {
        this.conferenceNo = exceptionConferenceNo
      }

      this.conferenceNo = formatCode(this.conferenceNo)

      const userInfo =  getLocalUserInfo()
      this.userName = name || getDisplayName() || userInfo.realName

      //从上次记忆中加载历史会议号记录
      let historyNumbers = JSON.parse(loadLocal("historyNumbers")) || []
      if (isArray(historyNumbers) && historyNumbers.length > 0) {
         this.historyNumberList = historyNumbers.slice(0,5)
      }

      //从上次操作记忆中加载勾选状态
      let isUseHuaTong = loadLocal("isUseHuaTong")

      if(this.joinParamData && this.joinParamData.turnLocalMic == 1){
        this.isEnableMicro = true
      }else if(this.joinParamData && this.joinParamData.turnLocalMic == 0){
        this.isEnableMicro = false
      }else{
        this.isEnableMicro = strToBoolean(isUseHuaTong, false)
      }



      let isUseShiPin = loadLocal("isUseShiPin")
      this.isEnableVideo = strToBoolean(isUseShiPin, false)

      let isUseYangShengQi = loadLocal("isUseYangShengQi")
      this.isEnableSpeaker = strToBoolean(isUseYangShengQi, true)

      let isUseMeiYan = loadLocal("isUseMeiYan")
      this.isEnableFace = strToBoolean(isUseMeiYan, false)

      this.$store.commit("meet/updateGlobalMeetState", {
          enableFace: this.isEnableFace
      })
    },

    onChangeFace(value) {
      this.$store.commit("meet/updateGlobalMeetState", {
          enableFace: value
      })
    },

    //记录会议输入历史记录
    openNumberHistory() {
      this.showHistoryWin = true
      this.historyNumberList
      .map(
        listItem => listItem.number === this.conferenceNo ? listItem.active = true : listItem.active = false
      )
    },
    onSelectedNumber(item, idx) {
      this.historyNumberList
      .map(
        listItem => listItem.active = false
      )
      item.active = true
      this.currentNumber = item.number
      this.$set(this.historyNumberList, idx, item)
    },
    onClearNumber() {
      this.historyNumberList = []
      removeLocal('historyNumbers')
      this.showHistoryWin = false
    },
    onConfirmNumber() {
      this.conferenceNo = this.currentNumber
      this.showHistoryWin = false
    },

    // 点击返回按钮 判断页面跳转逻辑 (存在token 跳转index页面,不存在token跳转登录页面)
    handleBack() {
      if (getToken()) {
        this.$router.push({ name: "home" });
      } else {
        this.$router.push({ name: "index" });
      }
    },



    // 加入会议完成回调
    joinFinishHandle(val) {
      saveLocal("isUseHuaTong", this.isEnableMicro);
      saveLocal("isUseYangShengQi", this.isEnableSpeaker);
      saveLocal("isUseShiPin", this.isEnableVideo);
      saveLocal("isUseMeiYan", this.isEnableFace); //记忆上次选择

      this.conferenceNo = formatCode(val.conference.conferenceNo)

      setLocalConferenceNo(this.conferenceNo)
      setDisplayName(this.userName)

      //本地存储 加入历史
      const filterNumbers = this.historyNumberList.filter(x => x.number === this.conferenceNo)
      if (filterNumbers.length === 0) {
        this.historyNumberList.unshift(
          {
            title: val.conference.title,//记录会议标题，非个人输入名称
            number: this.conferenceNo
          }
        )
        saveLocal('historyNumbers',this.historyNumberList)
      }
    },
  },
  // beforeRouteEnter(to, from, next) {

  //   const exceptionExitMsgObj = localStorage.getItem('exceptionExitMsg');

  //   if(exceptionExitMsgObj && from.name == 'meeting') {
  //     console.log('joinButton ', to, from);
  //     window.location.reload()
  //   } else {
  //     next({})
  //   }

  // }
}
</script>

<style scoped lang="less">
.flex_center() {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container {
   .nav {
     width: 100%;
     height: 88px;
     position: fixed;
     top: 0;
     left: 0;
     display: flex;
     align-items: center;
     .back-icon {
       width: 48px;
       height: 48px;
       margin-left: 30px;
     }
   }

   .input {
     width: 100%;
     height: 88px;
     font-size: 28px;
     font-weight: 400;
     padding-left: 0;
     padding-right: 0;
   }
   .input::after {
      left: 0;
      right: 0;
    }
   .button {
     width: 100%;
     height: 88px;
     background: #B5E6CA;
     border-radius: 10px;
     font-size: 32px;
     font-weight: 500;
     border:none;
   }
   .button.active {
     background: #1AB370 !important;
     color:#fff;
   }

   .content {
     margin:0 auto;
     padding-top: 80px;
     width: 83%;
     p.title {
       font-size: 48px;
       font-weight: 400;
       color: #333333;
       line-height: 64px;
     }
     .number {
       margin-top: 60px;
       .number-arrow {
         width: 32px;
         height: 32px;
         color:#999999;
       }
     }
     .name {
       margin-top: 24px;
     }
     .join {
       margin-top: 80px;
     }
     .back {
       background: #FFFFFF;
       color: #333333;
       border: 1px solid #E5E5E5;
       margin-top: 32px;
     }
     .set-item {
        height: 104px;
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        .label {
          display: inline-block;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
        }
        .switch {
          width: 96px;
          height: 48px;
        }
        .arrow {
          transform: rotate(180deg);
          margin-right: 20px;
        }
     }
     div.set-item.first {
       margin-top: 104px;
     }
   }

   .history-win {
     min-height: 240px;
     max-height: 704px;
     background: #FAFAFA;
     border-radius: 24px 24px 0px 0px;
     .head {
       position: fixed;
       width: 100%;
       z-index: 1;
       height: 144px;
       background: #FAFAFA;
       border-radius: 3.2vw 3.2vw 0px 0px;
     }
     .bar {
        width: 95px;
        height: 8px;
        background: #cccccc;
        border-radius: 4px;
        margin:0 auto;
        margin-top: 20px;
     }
     .link-area {
       height: 88px;
       width: 100%;
       .flex_center();
       margin-top: 12px;
       span {
         display: inline-block;
         font-size: 28px;
         font-weight: 400;
       }
       span:nth-child(1) {
        color: #FF8200;
        margin-left: 40px;
       }
       span:nth-child(2) {
         color: #1AB370;
         margin-right: 30px;
       }
     }
     .list {
       margin-top: 144px;
       margin-bottom:30px;
       .cell {
         padding-top: 0;
         padding-bottom: 0;
       }
       .colr_style{
        color: #1AB370;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
       }
       .set_name{
          width: 450px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: #666;
       }
       .item {
          height: 104px;
          font-size: 32px;
          .flex_center();
       }
       .item span {
         font-size: 32px;
       }
       .item span:nth-child(1) {
         color: #666666;
         width: 450px;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
       }
       .item span:nth-child(2){
         color: #000000;
       }
     }
   }
}
</style>
